import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ description, lang, meta, title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const structuredDataOrganization = `{"@context": "http://schema.org","@type": "Organization","name": "JR Web","legalName": "JR Web","url": "${site.siteMetadata.siteUrl}",
    "logo": "${site.siteMetadata.siteUrl}/jrweb-logo.png",
    "contactPoint": [{"@type": "ContactPoint","telephone": "+34667513400","email": "info@jrweb.es","contactType": "Customer service"
    }],
    "address": [{
      "@type": "PostalAddress",
      "addressLocality": "El Sauzal",
      "addressRegion": "Santa Cruz de Tenerife",
      "postalCode": "38360",
      "streetAddress": "Ctra. Vecinal de El Sauzal, 173"
    }]
  }`;

  const metaDescription = description || site.siteMetadata.description;

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        meta={[
          {
            name: 'description',
            content: metaDescription,
          },
          {
            property: 'og:title',
            content: title,
          },
          {
            property: 'og:description',
            content: metaDescription,
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:creator',
            content: site.siteMetadata.author,
          },
          {
            name: 'twitter:title',
            content: title,
          },
          {
            name: 'twitter:description',
            content: metaDescription,
          },
        ].concat(meta)}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: structuredDataOrganization,
        }}
      />
    </>
  );
};

SEO.defaultProps = {
  lang: 'es',
  meta: [],
  description: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
